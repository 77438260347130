<template>
  <div style="height: calc(-50px + 100vh); background: #333333;" ref="tabBox">
    <div class="liveStatus2" style="height: 100%;">
      <div class="boxCol flexB" style=" height: 100%;">
        <div class="tabBox liveWrap" style="height: 100%; background: #333333; border: none; border-radius: 0;padding:0;">
          <div class="flexL" style="justify-content: center; width: auto;height:100%;">
           <!-- <div class="live">
              <div ref= "map_div" style=" min-width: 100%; min-height: 100%; height: 100%; ">
                <Map ref="map" style="width: 100%; height: 100%; position: absolute; z-index: 2; border: 1px solid #808080;" :loadMapName="loadMap" />
                <img src="@/assets/images/map/map_empty_img.png" style = "z-index: 1; position: relative; width: 100%; height: auto; object-fit: fill;" />
              </div>
            </div> -->
            <template v-for="(index) in 9">
              <div class="live multiView" :key="index" style="width:33.3333%;height:33.3333%;">
                <span class="channel" style="z-index:5; width:60px; height:30px; text-align:center; line-height: 30px; position: absolute; top: 10px; right: 10px; background: #3c3c3ccc; color:white; font-weight: 400; font-size:1.2rem; border-radius: 5px; " >CH.{{ index }}</span>
                <div style="width: 100%;" v-if="getLiveData(index) != undefined" v-show="getLiveData(index).isLoad == true">
                  <div v-show="getLiveData(index).isLoad == true" class="flex" :class="{ 'live-active' : ( currentLive != null && currentLive.channelId == getLiveData(index).channelId )}" style="z-index: 2; width: 100%; height: 100%; opacity: 1; background-color: transparent; color: white; position: absolute; bottom: 0; "  @click="clickLive(getLiveData(index))" ></div>


                  <Video
                      :ref="`video_${getLiveData(index).channelId}`"
                      v-if="getLiveData(index) != undefined"
                      v-show="getLiveData(index).isLoad == true"
                      class="play-on play-3-view"
                      style="height: calc(100% - 35px);"
                      :src="`${getLiveData(index).streamServer.properties.liveUrl}/${checkDevice(getLiveData(index))}/${getLiveData(index).streamKey}/${getLiveData(index).streamServer.properties.livePostfix}`"
                      :isVod="false"
                      :isAuto="true"
                      :isControls="false"
                      :playerId="getLiveData(index).channelId"
                      :selectStreamId ="selectedSoundId"
                      :channelIndex="index"
                      :deviceType="getLiveData(index).assignedDeviceType"
                      @toggleSound ="toggleSound"
                      @isLoadedmetadata="playerLoadData"
                      @playerRemoveId="playerRemove"
                  />


                  <div class="liveChInfo" v-if="getLiveData(index).isLoad == true " style="position:absolute; bottom:0; height: 35px; background: rgba(0,0,0,0.5); width: 100%; text-align: center; display: flex;justify-content: space-around;" >
                    <span class="channel">{{getLiveData(index).eventTitle}}</span>
                    <span class="channel"  >{{getLiveData(index).assignedDeviceType != 'Mobile' ? getLiveData(index).assignedDeviceName : getLiveData(index).assignedDepartmentName}}</span>
                    <span class="channel"  >{{getLiveData(index).assignedDeviceType != 'Mobile' ? getLiveData(index).assignedUserName : getLiveData(index).assignedUserName}}</span>
                    <span clascss="channel"  >{{getLiveData(index).assignedDeviceType != 'Mobile' ? getLiveData(index).deviceModelName : "Mobile"}}</span>
                  </div>
                </div>
                <template v-if="(getLiveData(index) == undefined || getLiveData(index).isLoad == false)">
                  <div style="width: 100%;height:100%; z-index: 4; border: 1px solid #808080;" v-if="getLiveData(index) == undefined || getLiveData(index).isLoad == false" class="novideo">
                    <span>{{$t("ready-live")}}</span>
                  </div>
                </template>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
input[type="checkbox"] {
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 50%;
  border: none;
  appearance: none;
  cursor: pointer;
}
/*.live-active {
  border: 3px solid #e34307;
}*/
.live-box {
  /*color: #00ff44;*/
  color: white;
}
.live-box.active {
  color : #e34307;
}
.ch_info{
  width: 100%;
}
.liveInfoTitle{
  width: 45px;
  margin-left: 8px;
  color: #d3d3d3;
  font-size: 1.2rem;
}
.liveInfoCont{
  color: white;
  font-size: 1.2rem;
}
.liveChInfo>span{
  color: white;
  line-height: 3.8rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 1.2rem;
  -webkit-line-clamp: 1;
}
.liveStatus2 .boxCol .liveWrap .live{margin: 0;}
.multiView {
    margin-right: 0px !important;
}
.novideo{padding-top: 0 !important;}

.video-player .video-js{padding-top: 0;}
</style>
<script>
import moment from "moment";
import { fetchEvent } from "@/api/event";

import MapMarkerData from "@/components/map/data/MapMarkerData";
import MapLineData from "@/components/map/data/MapLineData";
import MapDataType from "@/components/map/enum/MapDataType";

import { baseUrl } from "@/config/env";
import { mapState } from "vuex";
import { fetchChannelList, getLiveUrl, fetchChannelCheckStream } from "@/api/channel";
import { fetchPointList, getPointTypeName } from "@/api/point";
import {fetchLocation} from "@/api/location";

// import Video from "@/components/video/videoPlayer";
// import WebRTC_WOWZA from "@/components/video/WebRTC_WOWZA";
import Video from "@/components/video/videoPlayer";

export default {
  name: "LiveStatus",
  components: {
    Video,
    // WebRTC_WOWZA,
  },
  data() {
    return {
      eventId : null,
      index: 0,
      accountId: localStorage.getItem("accountId"),
      baseUrl: baseUrl,
      moment: moment,
      currentPage: 0,
      total: 0,
      size: 9,
      channelStreamIds : [],

      isAutoPlay:true,
      liveList: [],
      viewChannelList : [],
      currentLive: null,
      eventInfo : null,
      loadMap : 'kakao',
      windowHeight: window.innerHeight,
      typeChangeShowHeight: 0,
      //liveVideoHeight: 0,
      contentHeight:0,
      isLocationReq : false,
      selectedSoundId: null
    };
  },
  computed: {
    ...mapState({
      gnbVisible: state => state.store.gnbVisible,
    }),
  },
  watch : {
    channelStreamIds(value){
      if(value.length > 9) {
        this.channelStreamIds.splice(9, 1);
      }
    },
    currentLang(value){
      this.initMapData(value);
    },
    typeChangeUpShowHeight(value){
      var upHeight = value;
      if(upHeight != this.typeChangeShowHeight) {
        upHeight = this.typeChangeShowHeight;
      }
      this.typeChangeShowHeight = upHeight;
    },
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", {nav: 1, sub: 3});

    this.typeChangeShowHeight = (this.windowHeight - 90) ;
  },
  async mounted() {
    this.eventId = this.$route.params.eventId;
    this.contentHeight = this.$refs.tabBox.clientHeight - 32;

    await this.getLiveList();
    this.getEvent();

    this.positionInterval = setInterval( () => {
      this.updateMapPosition()
    }, 1000);

    this.lineInterval = setInterval( () => {
      //this.updateMapLine()
    }, 2000);

    this.interval = setInterval(() => {
      this.getLiveList();
    }, 3000);

  },
  methods: {
    checkDevice(viewChannelData) {
      if(viewChannelData.assignedDeviceType === 'Drone'|| viewChannelData.assignedDeviceType === 'StationDrone'){
        return viewChannelData.streamServer.properties.droneApplication
      }
      return viewChannelData.streamServer.properties.application
    },
    checkStreamId (data) {
      return {
        channelStreamId: (data.assignedDeviceType === 'Drone' || data.assignedDeviceType === 'StationDrone') ? data.assignedDeviceLogId : data.assignedWorkerId,
        type: data.assignedDeviceType
      };
    },
    toggleSound(channelId){
      this.selectedSoundId = channelId
    },
    channelChange(){
    },
    //페이지 리사이즈
    handleResize(){
      this.windowHeight = window.innerHeight;
      this.typeChangeShowHeight = this.windowHeight - 90;
    },
    initMapData(value){
      if(value == 'en') {
        this.loadMap = 'google'
      } else {
        this.loadMap = 'kakao'
      }
      this.$nextTick(function(){
        this.getEventPoint();
        this.onMapCenter();

        if(this.currentLive != null){
          this.channelToMapMarker(this.currentLive);
          // this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
          // this.$refs.map.onLngLatToMoveCenter(this.currentLive.lng, this.currentLive.lat);
        }
      })
    },

    async getEventPoint() {
      let params = {
        eventId : this.eventId,
        serverTime : null,
      };
      await fetchPointList( params).then((res) => {
        if (res.data.result == 0) {
          this.pointServerTime = res.data.data.serverTime
          this.pointCount = res.data.data.total
          this.pointList = res.data.data.content
          this.pointList.forEach((element) => {
            element.selectId = element.index;
            element.selectType = "Point";
            element.isOwner = false;
            let pointMapMarker = new MapMarkerData();
            element.name = this.$t(getPointTypeName(element.type));
            pointMapMarker.setPointData(element);
            //this.$refs.map.onCreateMarker(MapDataType.ID_POINT, pointMapMarker);
          })
        }
      });
    },
    onMapCenter(){
      if(this.eventInfo == null) {
        return;
      }
      // this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      // if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
      //   this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
      // } else {
      //   this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
      // }
    },
    playerRemove(id){

      let findIndex = this.viewChannelList.findIndex(item => item.channelId == id);
      if(findIndex != -1){
        let channel = this.viewChannelList[findIndex];
        if(this.currentLive != null && this.currentLive.channelId == channel.channelId) {
          this.clickLive(channel);
        }
        this.viewChannelList.splice(findIndex,1);
      }
    },
    playerLoadData(id, isLoad){
      var isFindIndex = (data) => {
        return data.channelId == id;
      };

      var findIndex = this.viewChannelList.findIndex(isFindIndex)
      if(findIndex != -1) {
        this.viewChannelList[findIndex].isLoad = isLoad;
        this.$set(this.viewChannelList, findIndex, this.viewChannelList[findIndex]);
      }
    },
    getEvent() {
      if(this.eventId == undefined) {
        return;
      }
      fetchEvent(this.eventId).then((res) => {
        this.eventInfo = res.data.data;
        this.initMapData(this.currentLang);
      });
    },

    /**
     * 화면 보여주는 데이터의 인덱스 보정 후 값 가져오기
     * @param index
     */
    getLiveData(index){
      if(this.viewChannelList[index - 1] == undefined) {
        return null;
      }
      return this.viewChannelList[index - 1];
    },
    itemHandler(index){
      let channel = this.getLiveData(index);
      if(channel == null) {
        return;
      }

    },
    getLiveUrl(channelData){
      var url = getLiveUrl(channelData);
      if(url == '-') {
        return ''
      }
      return url
    },
    channelToMapMarker(channelData){
      var isFindIndex = (data) => {
        return data.channelId == channelData.channelId;
      };
      var findIndex = this.viewChannelList.findIndex(isFindIndex);
      let mapDataType = MapDataType.ID_DRONE
      let pointMapMarker = new MapMarkerData();
      pointMapMarker.id = channelData.channelId;
      pointMapMarker.lng = channelData.lng;
      pointMapMarker.lat = channelData.lat;
      pointMapMarker.rotate = channelData.azimuth;
      pointMapMarker.name = findIndex + 1;
      mapDataType;
      var isMain = false;
      if(this.currentLive != null && this.currentLive.channelId == channelData.channelId) {
        isMain = true;
      }
      pointMapMarker.isMain = isMain;
      // if(this.$refs.map.onIsMarker(mapDataType,channelData.channelId ) ) {
      //   this.$refs.map.onModifyMarkerAt(mapDataType, pointMapMarker);
      // } else {
      //   this.$refs.map.onCreateMarker(mapDataType, pointMapMarker);
      // }
    },

    clickLive(live){
      // this.$refs.map.onDeleteLineAt(MapDataType.ID_DRONE, live.channelId)
      if(this.currentLive == live){
        this.currentLive = null;
        // if(this.eventInfo.lng != null && this.eventInfo.lng != "" && this.eventInfo.lat != null && this.eventInfo.lat != "") {
          // this.$refs.map.onLngLatToMoveCenter(this.eventInfo.lng, this.eventInfo.lat)
        // } else {
          // this.$refs.map.onAddressToMoveCenter(this.eventInfo.address);
        // }
        return;
      }
      this.currentLive = live;
      // this.$refs.map.onSetZoomLevel(getDefaultMapZoomLevel(this.currentLang));
      // this.$refs.map.onLngLatToMoveCenter(this.currentLive.lng, this.currentLive.lat);
    },

    async updateMapPosition() {
      let params = {
        eventId :  this.eventId,
      };

      await fetchChannelList(params).then((res) => {
        let content = res.data.data.content;
        if(content.length > 0) {
          content.forEach(item => {
            var isFindIndex = (data) => {
              return data.channelId == item.channelId;
            };
            var findIndex = this.viewChannelList.findIndex(isFindIndex);
            if(findIndex != -1) {
              if(this.viewChannelList[findIndex].isLoad == true){
                this.channelToMapMarker(item);
              }
            }
          });
        }
      });
    },
    async getLiveList() {
      let params = {
        pageNumber: this.currentPage,
        pageSize: this.size,
        assignedStatus: "Assigned",
        eventId : this.eventId,
      };
      await fetchChannelList(params).then((res) => {
        this.total = res.data.data.total;
        this.liveList = res.data.data.content;

        //채널 추가 및 삭제 로직
        //삭제 아이디 찾기 위한 데이터 넣기
        let delIds = [];
        this.viewChannelList.forEach(item => {
          delIds.push(item.channelId.toString());
        });

        //채널 기존 채널 있는지 확인 및 삭제 아이디 에서 삭제
        this.liveList.forEach(live => {
          let findIndex = delIds.findIndex(item => item == live.channelId);
          if(findIndex != -1) {
            delIds.splice(findIndex,1);
          }
        });
        //삭제 아이디 있는 경우 채널에서 삭제
        delIds.forEach(delId => {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == delId);

          if(findIndex != -1){

            let channel = this.viewChannelList[findIndex];
            if(this.currentLive != null && this.currentLive.channelId == channel.channelId) {
              this.clickLive(channel)
            }
            // this.$refs.map.onDeleteMarkerAt(MapDataType.ID_DRONE, channel.channelId);
            this.viewChannelList.splice(findIndex,1);
          }
        })

        //채널 추가
        for(var i = 0; i < this.liveList.length ; i++) {
          this.addStreamChanelToChannel(this.liveList[i])
        }
      });
    },
    addStreamChanelToChannel(channel){
      fetchChannelCheckStream(channel.channelId).then((res)=>{
        if (res.data.result == 0 || res.data.data.bytesIn != 0) {
          let findIndex = this.viewChannelList.findIndex(item => item.channelId == channel.channelId);
          if(findIndex == -1){
            channel.isLoad = false;
            this.viewChannelList.push(channel)
          }
        }
      })
    },
    getChannelRefId(channel){
      var refId = null;
      if(channel.assignedDeviceType == "Mobile") {
        refId = channel.assignedWorkerId;
      } else {
        refId = channel.assignedDeviceLogId;
      }
      return refId;
    },
    updateMapLine(){
      if(this.currentLive == null) {
        return;
      }
      var refId = this.getChannelRefId(this.currentLive)
      if(refId != null) {
        this.getLocation(refId)
      }
    },
    getLocation(refId){
      if(this.isLocationReq == true) {
        return;
      }
      this.isLocationReq = true;

      // let mapDataType = MapDataType.ID_DRONE;
      let offset = 0; //this.$refs.map.onGetLineSize(mapDataType, refId);
      let params = {
        refId: refId,
        eventId: this.eventId,
        offsetIndex: offset,
      };
      fetchLocation(params).then((res) => {
        this.isLocationReq = false;
        if (res.data.result == 0) {
          if (res.data.data.content.length > 0) {
            res.data.data.content.sort(function (a, b) {
              return a.index - b.index;
            })
            this.createMapLine(MapDataType.ID_DRONE, refId, res.data.data.content);
          }
        }
      }).catch((e)=>{
        e;
        this.isLocationReq = false;
      });

    },
    createMapLine(mapDataType, id, locationGroup) {
      var mapLineData = new MapLineData();
      mapLineData.id = id;
      mapLineData.locationGroup = locationGroup;
      mapLineData.isMain = true;
      // if (this.$refs.map.onIsLine(mapDataType, id)) {
      //   this.$refs.map.onModifyLineAddAt(mapDataType, mapLineData);
      // } else {
      //   this.$refs.map.onCreateLine(mapDataType, mapLineData);
      // }
    },

    async handleCurrentChange(val) {
      this.currentPage = val - 1;
      this.viewChannelList = [];
      await this.getLiveList();
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
    clearInterval(this.lineInterval);
    clearInterval(this.positionInterval);
  },

};
</script>
