const MapDataType = Object.freeze({
  ID_USER: "ID_USER",
  ID_DRONE: "ID_DRONE",
  ID_CONTROLLER: "ID_CONTROLLER",
  ID_STREAMER : "ID_STREAMER",
  ID_STATION: "ID_STATION",
  ID_LIVE: "ID_LIVE",
  ID_CITY: "ID_CITY",
  ID_SITE: "ID_SITE",
  ID_SITE_CIRCLE: "ID_SITE_CIRCLE",

  ID_POINT: "ID_POINT",
  ID_WAYPOINT: "ID_WAYPOINT",

  ID_MAPPING : "ID_MAPPING",
  ID_MAPPING_CIRCLE : "ID_MAPPING_CIRCLE",
  ID_MAPPING_SQURE : "ID_MAPPING_SQURE",
  ID_MAPPING_POLYGON : "ID_MAPPING_POLYGON",
  ID_MAPPING_MARKER : "ID_MAPPING_MARKER", //mapping Marker 그릴때 사용
  ID_MAPPING_LINE : "ID_MAPPING_LINE",
  ID_MAPPING_HIDDEN_POLYGON : "ID_MAPPING_HIDDEN_POLYGON",
  ID_MAPPING_POLYLINE : "ID_MAPPING_POLYLINE", // mapping 안에 경로 그릴때 사용

  ID_MAPPING_POLYGON_LINE : "ID_MAPPING_POLYGON_LINE", //n각형 점 찍을 때 라인 그릴때 쓰는거.

  ID_AREA_POLYGON_RESTRICTED : "ID_AREA_POLYGON_RESTRICTED", // 비행제한구역
  ID_AREA_POLYGON_FORBIDDEN : "ID_AREA_POLYGON_FORBIDDEN", // 비행금지구역
  ID_AREA_POLYGON_CONTROL : "ID_AREA_POLYGON_CONTROL", // 관제권
  ID_AREA_POLYGON_ALERT : "ID_AREA_POLYGON_ALERT", // 경계구역
  ID_AREA_POLYGON_DANGER : "ID_AREA_POLYGON_DANGER", // 위험구역


  ID_THUMBNAIL: "ID_THUMBNAIL",
  ID_LIVE_MAP: "ID_LIVE_MAP",
  ID_LIVE_MAP_THERMAL: "ID_LIVE_MAP_THERMAL",
  ID_PLAYER_MARKER: "ID_PLAYER_MARKER",
  ID_PLAYER_LINE: "ID_PLAYER_LINE",
  ID_PLAYER_LINE_FULL: "ID_PLAYER_LINE_FULL",

  ID_MAP_INFO_USER: "ID_MAP_INFO_USER",
  ID_MAP_INFO_DRONE: "ID_MAP_INFO_DRONE",
  ID_MAP_INFO_POINT: "ID_MAP_INFO_POINT",
  ID_MAP_AI_MARKER : "ID_MAP_AI_MARKER", //지도에 디텍팅 객체 찍을때 사용

  // 미션제어-스테이션-스케쥴등록할떄 사용
  ID_SCHEDULE_STATION: "ID_SCHEDULE_STATION",

  //스테이션 메뉴, 정보창 지도 표출
  ID_SCHEDULE_STATION_NUMBER: "ID_SCHEDULE_STATION_NUMBER",
});

export default MapDataType;
